import React, { useState } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import ScrollToTop from "../component/ScrollToTop";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import ScrollTo from "react-scroll-to-top";
export default function Home() {
  const [input, setInput] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [verror, setVerror] = useState("");
  const [validations, setValidations] = useState({
    name: false,
    email: false,
    mobile: false,
    message: false,
  });

  const validationRules = {
    name: "Name is required",
    email: "Email is required",
    mobile: "Mobile is required",
    message: "Message is requied",
  };

  const handelInputs = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
    setValidations({ ...validations, [name]: false });
  };
  const handelSubmit = () => {
    for (const field in input) {
      if (!input[field]) {
        setValidations({ ...validations, [field]: true });
        setVerror(validationRules[field]);
        return;
      }
      const MOBILE_REGX = /^[0-9]{10}$/;
      const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (field === "email" && !EMAIL_REGEX.test(input.email)) {
        setValidations({ ...validations, email: true });
        setVerror("Valid Email Required.");
        return;
      }
      if (field === "mobile" && !MOBILE_REGX.test(input.mobile)) {
        setValidations({ ...validations, mobile: true });
        setVerror("Valid Mobile Required.");
        return;
      }
    }
    console.log(input);
    setInput({
      name: "",
      email: "",
      mobile: "",
      message: "",
    });
  };

  return (
    <>
      <ScrollToTop>
        <Header />
        <section className="aizelle_bg pb-lg-0 overflow-hidden">
          <div className="container">
            <div className="row align-items-center g-lg-5 g-4">
              <div className="col-lg-6 order-lg-1 order-2">
                <div>
                  <h1 className="color_text_green heading_h1">
                    Empowering Traders
                    with Advanced Forex Solutions
                  </h1>
                  <p className="sub_para ">
                    Discover a new era in forex trading with aizelle.
                    Our platform performs AI-driven strategies with
                    real-time insights and robust risk management to help you make smarter,
                    more informed financial decisions. Whether a novice or an expert, aizelle
                    empowers you to trade confidently and precisely.
                  </p>
                  <p className="sub_para mb-xl-4">
                    We merge cutting-edge technology with deep market knowledge to
                    create a trading platform that empowers you to succeed.
                  </p>
                  <div className="d-flex gap-2 ">
                    <a className="btn aizelle_btn sub_para" href="https://panel.aizelle.org/">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                <div className="hero_img">
                  <img
                    src="assets/images/home/ilustrator.png"
                    alt="Illustrator"
                    className="img-fluid aizelle_hero"
                  />
                  <img
                    src="assets/images/home/hero.png"
                    alt="Hero"
                    className="img-fluid position-relative robot_hero"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="section_padding position-relative why_Choose why_us_bg"
          id="whyus"
        >
          <div className="container-fluid">
            <div className="row g-xl-5">
              <div className="col-lg-12 text-center mb-xl-4 mb-3">
                <span className="sub_heading mb-2">Why Choose Us</span>
                <h2 className="heading_h2 mb-xl-5 mb-lg-4">
                  Unlock Your Trading Potential with
                  <br className="d-md-block d-none" />{" "}
                  Our Powerful Tools
                </h2>
              </div>
              <div className="col-lg-6 col-12 mx-auto text-center d-lg-block d-none">
                <img
                  src="assets/images/home/main.png"
                  alt=""
                  className="img-fluid main_img d-xl-block d-none"
                />
              </div>
              <div className="col-xl-6 col-12">
                <div className="row ">
                  <div className="col-xl-6 col-md-6 col-12 ">
                    <div className="why_choose_card ">
                      <div className="service_small_icon service_icon mb-2">
                        <img
                          src="assets/images/home/1.png"
                          alt=""
                          className="img-fluid images"
                        />
                      </div>
                      <h4 className="heading_h6 color_lightblue my-xl-3 my-sm-2 my-1">
                        Connecting Forex Traders Worldwide
                      </h4>
                      <p className="sub_para color_lightgray  mb-0">
                        Aizelle connects traders from over 180 countries, reflecting the truly global nature of the forex market. As forex remains the most liquid and accessible financial market, our platform supports multiple languages and offers localized trading tools.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="why_choose_card">
                      <div className="service_small_icon service_icon mb-2">
                        <img
                          src="assets/images/home/2.png"
                          alt=""
                          className="img-fluid images"
                        />
                      </div>
                      <h4 className="heading_h6 color_lightblue my-xl-3 my-sm-2 my-1">
                        Pioneering the Future of Forex Trading
                      </h4>
                      <p className="sub_para color_lightgray mb-0">
                        Aizelle envisions a future where forex trading is seamlessly integrated with advanced technology and accessible to all.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="why_choose_card">
                      <div className="service_small_icon service_icon  mb-2">
                        <img
                          src="assets/images/home/3.png"
                          alt=""
                          className="img-fluid images"
                        />
                      </div>
                      <h4 className="heading_h6 color_lightblue my-xl-3 my-sm-2 my-1">
                        Your Trading,<br className="d-none d-lg-block" /> Your Way
                      </h4>
                      <p className="sub_para color_lightgray mb-0">
                        Every trader is unique, and so are their goals. aizelle offers personalized trading solutions tailored to your specific needs and aspirations. Whether you’re looking to grow your portfolio or manage risk, we have the tools and support to help you succeed.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="why_choose_card">
                      <div className="service_small_icon service_icon  mb-2">
                        <img
                          src="assets/images/home/4.png"
                          alt=""
                          className="img-fluid images"
                        />
                      </div>
                      <h4 className="heading_h6 color_lightblue my-xl-3 my-sm-2 my-1">
                        Commitment to Your Success
                      </h4>
                      <p className="sub_para color_lightgray mb-0">
                        Aizelle is committed to your success. Our innovative platform, along with expert guidance, creates a trading environment where your potential is maximized. We are dedicated to providing you with the tools and support needed to achieve consistent in the forex market.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_padding" id="about">
          <div className="container">
            <div className="row g-lg-5 g-4 align-items-center">
              <div className="col-lg-6">
                <img
                  src="assets/images/home/about.webp"
                  alt="about Us"
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-lg-6">
                <span className="sub_heading mb-xl-2 mb-1">About Us</span>
                <h2 className="heading_h2 mb-xl-4 mb-sm-2 mb-1">
                  Aizelle Story
                </h2>
                <p className="sub_para mb-xl-4">
                  At Aizelle, our mission is to revolutionize forex trading by providing traders with cutting-edge technology and data-driven insights. We aim to empower traders of all levels with the tools they need to succeed in the fast-paced world of forex, ensuring that our platform is not only accessible but also optimized for consistent performance."


                  <br />
                  <br />

                  We have developed sophisticated algorithmic trading solutions that leverage advanced analytics and machine learning, allowing traders to automate their strategies and maximize efficiency.


                </p>

                <p className="sub_para d-flex align-items-center mb-xl-3 mb-md-2 mb-1">
                  <svg
                    width="30px"
                    height="30px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M7 12L9.89075 14.8907V14.8907C9.95114 14.951 10.049 14.9511 10.1094 14.8907V14.8907L17 8"
                        stroke="#60c784"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />{" "}
                    </g>
                  </svg>
                  Advanced Risk Management
                </p>
                <p className="sub_para  d-flex align-items-center mb-xl-3 mb-md-2 mb-1">
                  <svg
                    width="30px"
                    height="30px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M7 12L9.89075 14.8907V14.8907C9.95114 14.951 10.049 14.9511 10.1094 14.8907V14.8907L17 8"
                        stroke="#60c784"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                  Support When You Need It
                </p>
                <p className="sub_para d-flex align-items-center mb-xl-3 mb-md-2 mb-1">
                  <svg
                    width="30px"
                    height="30px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M7 12L9.89075 14.8907V14.8907C9.95114 14.951 10.049 14.9511 10.1094 14.8907V14.8907L17 8"
                        stroke="#60c784"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />{" "}
                    </g>
                  </svg>
                  Forex Traders with Precision and Flexibility
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="core_value_section section_padding color_white">
          <div className="container">
            <div className="row justify-contant-center ">
              <div className="col-lg-12 text-center mb-xl-5 mb-3 ">
                <h2 className="heading_h2 mb-xl-4 mb-2">
                  Features of Aizelle
                </h2>
                <p className="sub_para">
                  Unlock the full potential of our product with our amazing <br className="d-none d-lg-block" /> features and top-notch.
                </p>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="why_choose_card">
                  <div className="service_icon">
                    <img src="assets/images/home/Passive.png" alt="img-fluid" />
                  </div>
                  <h6 className="heading_h6 my-xl-3 my-2">
                    Advaced Tools and Technology                  </h6>
                  <p className="sub_para sub_para color_gray">
                    We provide a user-friendly dashboard interface with back-testing tools, predefined screeners, and technical software to comprehend market trends and test your strategies.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="why_choose_card">
                  <div className="service_icon">
                    <img
                      src="assets/images/home/Diversification.png"
                      alt="img"
                    />
                  </div>
                  <h6 className="heading_h6 my-xl-3 my-2">
                    Personalised Trading Strategies
                  </h6>
                  <p className="sub_para sub_para color_gray">
                    Pick from our pre-defined strategies, such as scalping, multi-leg, momentum-based, statistical arbitrage, index fund rebalancing, etc., or seek guidance from our advisory team.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="why_choose_card">
                  <div className="service_icon">
                    <img src="assets/images/home/Risk.png" alt="img" />
                  </div>
                  <h6 className="heading_h6 my-xl-3 my-2">Build Logic
                  </h6>
                  <p className="sub_para sub_para color_gray">
                    From Simple to complex strategies. Build all kinds of trading strategies from technical indicators to greeks using just keywords. Get a real-time snapshot of your strategies performance.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="why_choose_card">
                  <div className="service_icon">
                    <img src="assets/images/home/TotalSecurity.png" alt="img" />
                  </div>
                  <h6 className="heading_h6 my-xl-3 my-2">
                    Innovation, Integrity, and Forex Excellence
                  </h6>
                  <p className="sub_para sub_para color_gray">
                    aizelle core principles—innovation, integrity, and excellence—drive our commitment to enhancing your trading experience. In a market where currency exchange rates can fluctuate by over 30% annually.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="why_choose_card">
                  <div className="service_icon">
                    <img src="assets/images/home/synced.png" alt="img" />
                  </div>
                  <h6 className="heading_h6 my-xl-3 my-2">Empowering Forex Traders with Precision and Flexibility                  </h6>
                  <p className="sub_para sub_para color_gray">
                    At aizelle, our mission is to equip traders with the tools and strategies needed to thrive in the dynamic forex market. With a daily trading volume exceeding $6.6 trillion, the forex market demands precision and adaptability.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="why_choose_card">
                  <div className="service_icon">
                    <img src="assets/images/home/skin5.png" alt="img" />
                  </div>
                  <h6 className="heading_h6 my-xl-3 my-2">
                    Informed Decisions for Optimal Forex Outcomes
                  </h6>
                  <p className="sub_para sub_para color_gray">
                    Leverage aizelle's data-driven strategies to navigate the complexities of the $5 trillion daily traded forex market. Our cutting-edge analytics tools sift through vast amounts of market data to deliver actionable insights in real-time, helping you make informed decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_padding about_bg">
          <div className="container">
            <div class="row align-items-center g-lg-5 g-3">
              <div className="col-12 mb-xl-5 mb-3">
                <h2 className="heading_h2 text-center">How does It Works ?</h2>
                <p className="text-center sub_para">
                  Register, Complete your documentation and leave the rest for
                  us to provide for you.
                </p>
              </div>
              <div class="col-12 col-lg-6 text-center">
                <img
                  src="assets/images/home/about1.png"
                  alt="Illustration"
                  class="img-fluid"
                />
              </div>

              <div class="col-12 col-lg-6">
                <div class="steps">
                  <div class="step mb-4 d-flex align-items-start shadow rounded bg-white">
                    <div class="number_padding text-white rounded-circle d-flex align-items-start justify-content-center">
                      <span className="d-flex align-items-start justify-content-start sub_para">
                        1
                      </span>
                    </div>
                    <div class="step-content ms-3">
                      <h5 className="heading_h5">
                        Register / Login To Our Platform
                      </h5>
                      <p className="sub_para color_lightgray mb-0">
                        Begin by brainstorming and developing your trading strategy. Identify market conditions,
                        asset classes, and specific trading goals.
                      </p>
                    </div>
                  </div>
                  <div class="step mb-4 d-flex align-items-start shadow rounded bg-white">
                    <div class="number_padding text-white rounded-circle d-flex align-items-center justify-content-center">
                      <span className="d-flex align-items-start justify-content-start sub_para">
                        2
                      </span>
                    </div>
                    <div class="step-content ms-3">
                      <h5 className="heading_h5">
                        Access and Build Strategys
                      </h5>
                      <p className="sub_para color_lightgray mb-0">
                        Choose from pre-built trading algorithms or create a custom strategy tailored to your
                        unique trading style and goals.
                      </p>
                    </div>
                  </div>
                  <div class="step mb-4 d-flex align-items-start shadow bg-white">
                    <div class="number_padding text-white rounded-circle d-flex align-items-center justify-content-center">
                      <span className="d-flex align-items-start justify-content-start sub_para">
                        3
                      </span>
                    </div>
                    <div class="step-content ms-3">
                      <h5 className="heading_h5">
                        Start Strategy Testing  and Approv
                      </h5>
                      <p className="sub_para color_lightgray mb-0">
                        Test your strategy using historical data to assess its effectiveness.
                        After thorough testing, review the strategy based on performance reports. Make necessary
                        adjustments to ensure it meets your trading criteria and risk tolerance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section_padding section_testimonal">
          <div className="container">
            <div className="d-flex gap-2 justify-content-end align-items-center">
              <div className="btn_about_prev swiper-button-about-prev">
                <img
                  src="assets/images/home/rightwhitearrow.svg"
                  alt="Previous"
                  className="text-white"
                />
              </div>
              <div className="btn_about_next swiper-button-about-next">
                <img src="assets/images/home/leftwhitearrow.svg" alt="Next" />
              </div>
            </div>
            <Swiper
              modules={[Navigation, Pagination]}
              slidesPerView={1}
              navigation={{
                nextEl: ".swiper-button-about-next",
                prevEl: ".swiper-button-about-prev",
              }}
              loop={true}
            >
              <SwiperSlide>
                <div className="testimonal_card">
                  <div className="row align-items-center g-lg-5 g-4">
                    <div className="col-lg-3 col-md-5">
                      <div className="d-block w-100">
                        <img
                          src="assets/images/home/test_1.png"
                          alt="Testimonial"
                          className="img-fluid w-100 mx-auto mx-lg-0"
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-7">
                      <p className="sub_para">
                        Thankyou for making it a wonderful journey for me.
                        My discretionary trades did not went well but all algo tardes with your portal did great Job. Henceforth no discretionary tardes only aizelle😀
                      </p>
                      <h4 className="heading_h6">Mobarok Hossain</h4>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonal_card">
                  <div className="row align-items-center g-lg-5 g-4">
                    <div className="col-lg-3 col-md-5">
                      <div className="d-block w-100">
                        <img
                          src="assets/images/home/test_1.png"
                          alt="Testimonial"
                          className="img-fluid w-100 mx-auto mx-lg-0"
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-7">
                      <p className="sub_para">
                      “Interface is very seamless and smooth.. one more thing charges are very low..😍😍😍 ”
                      </p>
                      <h4 className="heading_h6">Robert S. Dennis
                      </h4>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonal_card">
                  <div className="row align-items-center g-lg-5 g-4">
                    <div className="col-lg-3 col-md-5">
                      <div className="d-block w-100">
                        <img
                          src="assets/images/home/test_1.png"
                          alt="Testimonial"
                          className="img-fluid w-100 mx-auto mx-lg-0"
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-7">
                      <p className="sub_para">
                      Super-fast execution. Proper support and guidance from aizelle Team whenever I stuck in my strategy.
                      </p>
                      <h4 className="heading_h6">Mike C. Escalante
                      </h4>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </section>

        <section className="section_padding faq" id="faq">
          <div className="container">
            <div className="row g-lg-5 g-md-4 g-2">
              <div className="col-lg-12">
                <span className="sub_heading mb-xl-2 mb-1">
                  Frequently Asked Questions
                </span>
                <h2 className="heading_h2">Information about Platform</h2>
              </div>
              <div className="col-lg-6">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        What is Aizelle ?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body sub_para">
                        <strong>
                          Aizelle
                        </strong>{" "}
                        is a comprehensive trading platform offering advanced tools,
                        real-time data, and robust security features for a superior trading experience.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        How do I start trading?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body sub_para">
                        <strong>
                          This is the second item's accordion body.
                        </strong>{" "}
                        Sign up on our platform, complete the verification process,
                        and start trading with our user-friendly tools.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        What makes aizelle different from other platforms?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body sub_para">
                        <strong>
                          Aizelle
                        </strong>{" "}
                        combines advanced technology, user-friendly design,
                        and exceptional support to provide a unique trading experience.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="accordion" id="accordionExample4">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingfour">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsefore"
                        aria-expanded="true"
                        aria-controls="collapsefore"
                      >
                        How secure is Aizelle?
                      </button>
                    </h2>
                    <div
                      id="collapsefore"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingfour"
                      data-bs-parent="#accordionExample4"
                    >
                      <div className="accordion-body sub_para">
                        <strong>
                          aizelle
                        </strong> uses state-of-the-art encryption and security
                        measures to protect your assets and
                        personal information.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading5">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse5"
                        aria-expanded="false"
                        aria-controls="collapse5"
                      >
                        What is Algo Trading?
                      </button>
                    </h2>
                    <div
                      id="collapse5"
                      className="accordion-collapse collapse"
                      aria-labelledby="heading5"
                      data-bs-parent="#accordionExample4"
                    >
                      <div className="accordion-body sub_para">
                        <strong>
                          Algo trading
                        </strong>{" "}
                        uses computer programs to execute trades based on predefined rules and algorithms. These algorithms can analyze market data, identify patterns, and execute trades at high speeds, often faster than human traders can react.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading6">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse6"
                        aria-expanded="false"
                        aria-controls="collapse6"
                      >
                        Which is better, Manual Trading or Algo Trading?
                      </button>
                    </h2>
                    <div
                      id="collapse6"
                      className="accordion-collapse collapse"
                      aria-labelledby="heading6"
                      data-bs-parent="#accordionExample4"
                    >
                      <div className="accordion-body sub_para">
                        <strong>
                          This is the third item's accordion body.
                        </strong>{" "}
                        The best approach often depends on individual preferences, risk tolerance, and trading style.
                        Many traders use a combination of both methods. Algo trading can be used for high-frequency
                        trading and automated strategies, while manual trading can be used for longer-term investments
                        and discretionary decisions.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="section_padding">
                    <div className="container">
                        <div class="row align-items-center g-0 ">
                            <div className="col-12 mb-xl-5 mb-3 h-100" >
                                <h2 className="heading_h2 text-center">Join our community</h2>
                            </div>
                            <div class="col-12 col-lg-6  text-white  ">
                                <div className="monthly h-100 join_community">
                                    <h6 className="heading_h6 mb-lg-3 mb-2">Monthly Subscription</h6>
                                    <h5 className="heading_h5 mb-lg-3 mb-2"><h2 className="heading_h2">$29</h2>per month</h5>
                                    <p className="sub_para mb-0">Full access for less than $1 a day</p>
                                    <button class="btn aizelle_btn_border mt-4">Sign Up</button>
                                </div>
                            </div>

                            <div class="col-12 col-lg-6">
                                <div className="why_us h-100 join_community">
                                    <h6 className="heading_h6 mb-xl-4 mb-lg-3 mb-2">Why Us</h6>
                                    <ul className="list-unstyled sub_para mb-lg-3 mb-0">
                                        <li>Tutorials by industry experts</li>
                                        <li>Peer & expert code review</li>
                                        <li>Coding exercises</li>
                                        <li>Access to our GitHub repos</li>
                                        <li>Community forum</li>
                                        <li>Flashcard decks</li>
                                        <li>New videos every week</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </section> */}
        {/* <section className="section_padding overflow-hidden position-relative mission_section why_Choose">
                    <div className="container">
                        <div className="row g-md-4 g-3 pe-md-3 py-lg-5">
                            <div className="col-lg-6">
                                <div className="cards_style hover-effect me-xl-2">
                                    <div className="d-flex justify-content-center">
                                        <img src="assets/images/home/our_mission_image.png" alt="" className="img-fluid mb-lg-3 mb-2" />
                                    </div>
                                    <h6 className="heading_h6 text-center">Our Mission</h6>
                                    <p className="sub_para text-center">Tempora incidunt ut labore et dolore magnam aliruam quaerat volurtatem aut enim <br className="d-lg-block d-none" /> ad minima veniam ruis  nostrum exercitatio.</p>
                                </div >
                            </div >
                            <div className="col-lg-6">
                                <div className="cards_style hover-effect">
                                    <div className="d-flex justify-content-center ">
                                        <img src="assets/images/home/our_vision_image.png" alt="" className="img-fluid mb-lg-3" />
                                    </div>
                                    <h6 className="heading_h6 text-center">Our Vision</h6>
                                    <p className="sub_para text-center">Quis autem vel eum iure reprehenderit ruin voluta velit esse quam nihil molestiae conseuatur illum aui dolorem <br className="d-xl-block d-none" />  eum fugiat ruo.</p>
                                </div>
                            </div>
                            <div class="mb-0 position-absolute left_right_shape top_right_shape">
                                <img src="assets/images/home/mission_vision_background_shape.png" alt="" class="img-fluid d-lg-block d-none" />
                            </div>
                        </div >

                        <div class="mb-0 position-absolute mision_left_right_shape top_bottom_shape ">
                            <img src="assets/images/home/mission_vision_left_shape.png" alt="" class="img-fluid d-lg-block d-none" />
                        </div>
                    </div >
                </section > */}
        <section class="section_padding yellow_bg" id="contact">
          <div class="container">
            <div class="row align-items-center g-4">
              <div className="col-12">
                <div className="text-center mb-xl-5 mb-3 text-white">
                  <h6 className="sub_heading">Contact Us</h6>
                  <h2 className="heading_h2">Connect with Aizelle</h2>
                  <p className="sub_para">
                    Contact our team for personalized support and guidance.{" "}
                    <br className="d-none d-md-block" /> We're committed to your
                    success and eager to hear from you.
                  </p>
                </div>
              </div>
              <div class="col-12 text-center mb-4">
                <div class="contact-form bg-white rounded w-100 pb-0">
                  <div className="d-flex align-items-end">
                    <div className="contact_img pb-2">
                      <img
                        src="assets/images/home/man.png"
                        alt="Contact Image"
                        class="d-md-block d-none  "
                      />
                    </div>
                    <div className="w-100 mb-5">
                      <div class="form-group">
                        <input
                          type="text"
                          name="name"
                          className={
                            validations.name && verror
                              ? "form-control rounded-left error_border "
                              : "form-control rounded-left mb-lg-4 mb-3"
                          }
                          placeholder="Your Name"
                          required=""
                          value={input.name}
                          onChange={handelInputs}
                        />
                        {validations.name && verror && (
                          <>
                            <p className="d-flex my-1 error_message sub_para">
                              <img
                                src="assets/images/home/warning.svg"
                                alt=""
                                className="me-1"
                              />
                              {verror}
                            </p>
                          </>
                        )}
                      </div>
                      <div class="form-group">
                        <input
                          type="email"
                          name="email"
                          className={
                            validations.email && verror
                              ? "form-control rounded-left error_border"
                              : "form-control rounded-left mb-lg-4 mb-3"
                          }
                          placeholder="Your Email"
                          required=""
                          value={input.email}
                          onChange={handelInputs}
                        />
                      </div>
                      {validations.email && verror && (
                        <>
                          <p className="d-flex my-1 error_message sub_para">
                            <img
                              src="assets/images/home/warning.svg"
                              alt=""
                              className="me-1"
                            />
                            {verror}
                          </p>
                        </>
                      )}
                      <div class="form-group">
                        <input
                          type="tel"
                          name="mobile"
                          className={
                            validations.mobile && verror
                              ? "form-control rounded-left error_border"
                              : "form-control rounded-left mb-lg-4 mb-3"
                          }
                          placeholder="Your Mobile"
                          required=""
                          value={input.mobile}
                          onChange={handelInputs}
                        />
                      </div>
                      {validations.mobile && verror && (
                        <>
                          <p className="d-flex my-1 error_message sub_para">
                            <img
                              src="assets/images/home/warning.svg"
                              alt=""
                              className="me-1"
                            />
                            {verror}
                          </p>
                        </>
                      )}
                      <div class="form-group">
                        <textarea
                          class="form-control"
                          rows="4"
                          name="message"
                          className={
                            validations.message && verror
                              ? "form-control rounded-left error_border"
                              : "form-control rounded-left mb-lg-4 mb-3"
                          }
                          placeholder="Message"
                          required=""
                          value={input.message}
                          onChange={handelInputs}
                        ></textarea>
                      </div>
                      {validations.message && verror && (
                        <>
                          <p className="d-flex my-1 error_message sub_para">
                            <img
                              src="assets/images/home/warning.svg"
                              alt=""
                              className="me-1"
                            />
                            {verror}
                          </p>
                        </>
                      )}
                      <div className="d-flex">
                        <button
                          type="submit"
                          class="btn aizelle_btn sub_para"
                          onClick={handelSubmit}
                        >
                          SUBMIT NOW
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <ScrollTo smooth={true} className="aizelle_top" />
      </ScrollToTop>
    </>
  );
}
