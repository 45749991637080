import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Privacypolicy from './pages/Privacypolicy';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" index element={<Home />} />
      <Route path="/privacypolicy" element={<Privacypolicy />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
